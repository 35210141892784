/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";

:root {
  --menu-bg: #fff;
  --menuitem-text-color: #fff;
  --surface-ground: #F2F4F6;
  --sidebar-shadow: 0px 4px 50px #D9DDFC;
  --sidebar-border: 1px solid transparent;
  --card-shadow: 0px 4px 30px rgba(221, 224, 255, .54);

  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  font-variation-settings: normal;
  --font-family:"Helvetica", sans-serif;
  --font-feature-settings: "cv02","cv03","cv04","cv11";
  --surface-a:#ffffff;
  --surface-b:#f9fafb;
  --surface-c:#f3f4f6;
  --surface-d:#e5e7eb;
  --surface-e:#ffffff;
  --surface-f:#ffffff;
  --text-color:#4b5563;
  --text-color-secondary:#6b7280;
  --primary-color:#6366f1;
  --primary-color-text:#ffffff;
  --surface-0: #ffffff;
  --surface-50: #f9fafb;
  --surface-100: #f3f4f6;
  --surface-200: #e5e7eb;
  --surface-300: #d1d5db;
  --surface-400: #9ca3af;
  --surface-500: #6b7280;
  --surface-600: #4b5563;
  --surface-700: #374151;
  --surface-800: #1f2937;
  --surface-900: #111827;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
  --content-padding:1.25rem;
  --inline-spacing:0.5rem;
  --border-radius:6px;
  --surface-ground:#f9fafb;
  --surface-section:#ffffff;
  --surface-card:#ffffff;
  --surface-overlay:#ffffff;
  --surface-border:#dfe7ef;
  --surface-hover:#f6f9fc;
  --focus-ring: 0 0 0 0.2rem #c7d2fe;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #eef2ff;
  --highlight-text-color: #4338ca;
  color-scheme: light;
  --transition-duration: 0.2s;

  --logo-color: var(--primary-color);
  --menu-bg: var(--surface-overlay);
  --root-menuitem-text-color: var(--primary-color);
  --menuitem-text-color: var(--text-color);
  --menuitem-icon-color: var(--primary-color);
  --menuitem-hover-bg-color: var(--surface-hover);
  --popup-submenu-item-text-color: var(--text-color);
  --popup-submenu-item-icon-color: var(--primary-color);
  --popup-submenu-item-hover-bg-color: var(--surface-hover);

  --bs-success-rgb: 44, 197, 94;
  --bs-warning-rgb: 249, 115, 22;
  --bs-warning-bg-subtle: #ffe4d1;
}

.table-success {
  --bs-table-striped-bg: #b8edc9 !important;
  --bs-table-hover-bg: #2dc55e !important;
}

/* Style pour WebKit */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

/* Style pour Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}


body {
  background-color: var(--surface-ground) !important;
  font-family: var(--font-family) !important;
  font-size: 14px;
}

a {
  text-decoration: none !important;
}

.p-sidebar-header{
    display: none;
}
th{
    min-width: 300px
}

.table-color-1{
    background-color: rgb(57, 57, 57);
    color: #fff;
}
.table-color-2{
    background-color: rgb(24, 24, 24);
    color: #fff;
}

.navSidebar ul li {
    list-style: none;
    a{
        // border: 1px solid black;
        font-size: 16px;
        color: #fff;
        text-decoration: none;
    }
}

.title-breadcrumb{
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
}

/* Cible la scrollbar horizontale uniquement */
::-webkit-scrollbar:horizontal {
    height: 5px; /* La hauteur de la scrollbar */
}

/* La partie de la scrollbar */
::-webkit-scrollbar {
    background-color: #f1f1f1; /* Couleur de fond de la scrollbar */
}

/* La partie glissable de la scrollbar */
::-webkit-scrollbar-thumb {
    background-color: #888; /* Couleur du thumb (le glisseur) */
    border-radius: 6px; /* Arrondir les bords du glisseur */
}

/* Lorsque le thumb est en survol */
::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Changer la couleur du thumb lorsqu'il est survolé */
}

/* La piste de la scrollbar */
::-webkit-scrollbar-track {
    background-color: #e1e1e1; /* Couleur de la piste */
}

:host ::ng-deep {
    [pDraggable] {
        cursor: move;
    }
}


.p-datatable-table {
    // height: calc(100vh - 300px) !important;
    height: 200px !important;
    table tr{
        height: 20px;
    }
}
.p-datatable-table tr{
    height: 20px !important;
}

.p-datatable>.p-datatable-wrapper {
    // height: 450px;
    overflow: auto;
}

.layout-content-wrapper {
  padding: 2rem;
}

.layout-content-wrapper.sidebarOpened {
  margin-left: 15rem;
  transition: margin-left var(--transition-duration);
}

.w-100dvw {
  width: 100dvw;
}

.h-100dvh {
  height: 100dvh;
}

.card {
  background: var(--surface-card);
  border: 1px solid var(--surface-border) !important;
  padding: 2rem;
  margin-bottom: 0.4rem;
  box-shadow: var(--card-shadow);
  border-radius: 12px;
  overflow: hidden;
}

.card-400 {
  min-height: 400px;
}

.layout-sidebar {
  transform: translate(-100%);
  transition: transform var(--transition-duration);
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 15rem;
  background: var(--menu-bg);
  border-right: var(--sidebar-border);
  box-shadow: var(--sidebar-shadow);
  display: flex;
  flex-direction: column;
}

.layout-sidebar.sidebarOpened {
  transform: translate(0%);
}

.layout-sidebar .sidebar-header {
  padding: 2.5rem 0 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #005779;
  margin: 0 0 20px 0;
}

.layout-sidebar .layout-menu-container {
  overflow: auto;
  flex: 1;
  padding-bottom: 2rem;
}

.layout-sidebar .layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.layout-sidebar .layout-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
  font-size: .857rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--root-menuitem-text-color);
  padding: 1rem 2rem;
}

.layout-sidebar .layout-menu .layout-root-menuitem>.layout-menuitem-root-text {
  font-size: .857rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--root-menuitem-text-color);
  padding: 1rem 2rem;
}

.layout-sidebar .layout-menu ul li.active {
  background: var(--menuitem-icon-color);
  color: #fff;
}

.layout-sidebar .layout-menu ul li.active a {
  color: #fff;
}

.layout-sidebar .layout-menu ul li.active i {
  color: #fff;
}

.layout-sidebar .layout-menu ul a {
  display: flex;
  align-items: center;
  position: relative;
  outline: 0 none;
  color: var(--menuitem-text-color);
  cursor: pointer;
  padding: .75rem 2rem;
  transition: background-color var(--transition-duration), box-shadow var(--transition-duration);
}

.layout-sidebar .layout-menu a {
  -webkit-user-select: none;
  user-select: none;
}

.layout-sidebar .layout-menu ul a.active-route {
  font-weight: 700;
}

.layout-sidebar .layout-menu ul a .layout-menuitem-icon {
  margin-right: .5rem;
  color: var(--menuitem-icon-color);
  font-size: 20px;
}

.layout-topbar {
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.layout-topbar .avatar {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.layout-topbar .topbar-menu {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none
}

.layout-topbar .topbar-menu li.topbar-profile {
  margin-left: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center
}

.layout-topbar .topbar-menu li.topbar-profile img {
  width: 2rem;
  height: 2rem
}

.layout-topbar .topbar-menu li.topbar-profile button {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  transition: box-shadow var(--transition-duration)
}

.layout-topbar .topbar-menu li.topbar-profile button:focus {
  box-shadow: var(--focus-ring)
}

.layout-topbar .topbar-menu li.topbar-search .p-inputtext {
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-radius: 2rem
}

.layout-topbar .topbar-menu li.topbar-profile {
  margin-left: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}


#customerFile td {
  padding: 1rem;
}

.pointer {
  cursor: pointer;
}

.tnsImage i {
  font-size: 60px;
}

.tnsImage {
  width: 75px;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tns-col {
  height: calc(100dvh - 63px);
  overflow-y: auto;
}

.timeline-container {
  position: relative;
  margin: 0 auto;
  padding: 20px 0;
  width: 90%;
  height: 100%;
  border-left: 3px solid #DADEE3;/* Ligne centrale de la timeline */
}

.timeline-item {
  position: relative;
  margin-bottom: 20px;
}

#sidebarContent {
  position: fixed;
  top: 0;
  right: -60dvw;
  width: 60dvw;
  height: 100%;
  background-color: var(--surface-ground);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  z-index: 99;
  overflow-y: auto;
}

#sidebarContent.active {
  right: 0;
}

#sidebarContent .header {
  width: 60dvw;
  z-index: 100;
}

#sidebarContent .content {
  height: calc(100dvh - 63px);
  margin-top: 63px;
  overflow: hidden;
}

#sidebarContent .close {
  font-size: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-circle {
  position: absolute;
  left: -12px;
  top: 10px;
  width: 20px;
  height: 20px;
  background-color: #dadee3;
  border-radius: 50%;
  border: 3px solid #f9fafb;
}

.timeline-content {
  margin-left: 15px;
  // padding: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.timeline-content h6 {
  margin: 0;
  font-size: 18px;
  // color: #3498db;
  padding: 10px;
}

.timeline-content p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #333;
}

.timeline-content .event-date {
  font-size: 12px;
  color: #333;
  padding: 10px;
}

.timeline-content .event-description {
  margin: 10px 0 0 0;
}

#filterOptionsWrapper {
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 99;
  width: 800px;
}

.stat-icon-corner {
  position: absolute;
  right: 0;
  padding: 25px 28px 0 0px;
  bottom: 0;
  border-radius: 100% 0 0;
  width: 140px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.stat-icon-corner i {
  font-size: 50px;
}

.btn-success {
  --bs-btn-bg: #2cc55e;
  --bs-btn-border-color: #2dc55e;
}


.p-button {
  border-radius: 6px;
  padding: 0.5rem 1rem;
}

.auto-height-textarea {
  min-height: 50px !important;         /* Hauteur minimale */
  max-height: 150px;        /* Hauteur maximale */
  overflow-y: auto;         /* Barre de défilement si le texte dépasse max-height */
  padding: 0.5rem;          /* Ajuste le padding pour un affichage optimal */
  line-height: 1.5;         /* Définit l'espacement entre les lignes */
  font-size: 1rem;          /* Ajuste la taille de la police */
  width: 100%;              /* Prend toute la largeur disponible */
  border: 1px solid #ced4da;/* Bordure par défaut */
  border-radius: 0.25rem;   /* Bordure arrondie */
  resize: none;             /* Désactive le redimensionnement manuel */
  box-sizing: border-box;   /* Inclut le padding et la bordure dans la taille */
}

.auto-height-textarea:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 5px rgba(128, 189, 255, 0.5); /* Met en surbrillance le champ lors du focus */
}

.identificationContent {
  width: calc( 430px - 122px);
}

.card-deco-icon {
  position: relative;
  overflow: hidden;
  color: #fff;
}

.card-deco-icon .fs-1{
  font-size: 60px !important;
}
.card-deco-icon .icon-illustration {
  position: absolute;
  right: -20px;
  transform: rotate(-15deg) translate(-30px, 21px);
  font-size: 100px;
  opacity: 0.3;
  color: #fff;
  transition: all 1s ease;
}
.card-deco-icon:hover .icon-illustration {
  position: absolute;
  right: -20px;
  transform: rotate(-10deg) translate(-20px, 0px);
}

.big-check {
  width: 1.5rem;
  height: 1.5rem;
}

.p-splitbutton-defaultbutton {
  border-radius: 6px 0 0 6px !important;
}

.p-splitbutton-menubutton {
  border-radius: 0 6px 6px 0;
}
.p-tieredmenu-root-list {
  padding: 0;
}
.p-submenu-list {
  padding: 0;
}

.w-100 .p-tag {
  width: 100%;
}
